import React from "react";
import axios from "axios";
import { Button, message, Icon, Modal } from "antd";

import Code from "./Code";
import Markdown from "./Markdown";
import Raw from "./Raw";
import Header from "./Header";
import {
  getBaseUrl,
  getRedirectUrl,
  getPlabDownloadUrl
} from "./Util/urlHelper";
import { ButtonProps } from "antd/lib/button";

axios.defaults.baseURL = getBaseUrl();
const protocolCheck = require("./Util/protocolcheck");
const { confirm } = Modal;

class PlabWeb extends React.Component {
  redirectUrl: string;
  redirectBtn: any;

  state = {
    cells: [],
    currentSection: 0,
    sectionName: "",
    sections: [],
    loading: true,
    sourceFloder: ""
  };

  constructor(props: any) {
    super(props);
    this.changeSection = this.changeSection.bind(this);
    this.redirect = this.redirect.bind(this);

    this.redirectUrl = getRedirectUrl() + this.getChapterName();
    this.readJson();
  }

  componentDidUpdate() {
    window.parent.postMessage(
      {
        func: "getHeight",
        message: document.body.scrollHeight
      },
      "*"
    );
  }

  getOsInfo() {
    var userAgent = navigator.userAgent.toLowerCase();
    var name = "Unknown";
    var version = "Unknown";
    if (userAgent.indexOf("win") > -1) {
      name = "Windows";
      if (userAgent.indexOf("windows nt 5.0") > -1) {
        version = "Windows 2000";
      } else if (
        userAgent.indexOf("windows nt 5.1") > -1 ||
        userAgent.indexOf("windows nt 5.2") > -1
      ) {
        version = "Windows XP";
      } else if (userAgent.indexOf("windows nt 6.0") > -1) {
        version = "Windows Vista";
      } else if (
        userAgent.indexOf("windows nt 6.1") > -1 ||
        userAgent.indexOf("windows 7") > -1
      ) {
        version = "Windows 7";
      } else if (
        userAgent.indexOf("windows nt 6.2") > -1 ||
        userAgent.indexOf("windows 8") > -1
      ) {
        version = "Windows 8";
      } else if (userAgent.indexOf("windows nt 6.3") > -1) {
        version = "Windows 8.1";
      } else if (
        userAgent.indexOf("windows nt 6.2") > -1 ||
        userAgent.indexOf("windows nt 10.0") > -1
      ) {
        version = "Windows 10";
      } else {
        version = "Unknown";
      }
    } else if (userAgent.indexOf("iphone") > -1) {
      name = "Iphone";
    } else if (userAgent.indexOf("mac") > -1) {
      name = "Mac";
    } else if (
      userAgent.indexOf("x11") > -1 ||
      userAgent.indexOf("unix") > -1 ||
      userAgent.indexOf("sunname") > -1 ||
      userAgent.indexOf("bsd") > -1
    ) {
      name = "Unix";
    } else if (userAgent.indexOf("linux") > -1) {
      if (userAgent.indexOf("android") > -1) {
        name = "Android";
      } else {
        name = "Linux";
      }
    } else {
      name = "Unknown";
    }
    return { name, version };
  }

  getChapterName() {
    return this.getUrlParam("chapterName") === null
      ? "爬虫"
      : this.getUrlParam("chapterName");
  }

  async readJson() {
    await axios
      .get(
        `/plab/chapters/single?name=${this.getChapterName()}&secret=mfe_plab`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
          }
        }
      )
      .then(response => {
        if (response.data.msg === "课程不存在") {
          this.setState({
            loading: false
          });
          return;
        }
        let sections = response.data.data;
        if (sections) {
          let data = JSON.parse(sections[0]["metadata"]);
          if (sections.length > 0) {
            this.setState({
              cells: data.cells,
              currentSection: 0,
              sectionName: sections[0].name,
              sections: sections,
              loading: false,
              sourceFloder: this.getChapterName() + "/" + sections[0].name + "/"
            });
          }
        }
      });
  }

  getUrlParam(name: string) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
  }

  changeSection(index: number) {
    let data = JSON.parse(this.state.sections[index]["metadata"]);
    this.setState({
      cells: data.cells,
      currentSection: index,
      sectionName: this.state.sections[index]["name"],
      sourceFloder:
        this.getChapterName() + "/" + this.state.sections[index]["name"] + "/"
    });
    const container = document.querySelector(".section-container");
    if (container) {
      container.scrollTop = 0;
    }
  }

  redirect() {
    console.log("redirect");
    message.config({
      top: 400
    });

    protocolCheck(
      this.redirectUrl,
      () => {
        confirm({
          title: "提示",
          content: "没有找到plab，是否下载？",
          okText: "下载",
          cancelText: "取消",
          okButtonProps: {
            href: getPlabDownloadUrl(this.getOsInfo().name),
            download: "plabInstaller",
            style: {
              marginLeft: "5px"
            }
          } as ButtonProps
        });
      },
      () => {
        message.success("已向plab发起跳转请求，根据浏览器的提示打开plab吧.", 5);
      }
    );
  }

  generateCompoment(item: any, index: number) {
    if (item.cell_type === "code") {
      return <Code cell={item} key={"code" + index + this.state.sectionName} />;
    } else if (item.cell_type === "markdown") {
      return (
        <Markdown
          cell={item}
          key={"markdown" + index + this.state.sectionName}
          sourceFloder={this.state.sourceFloder}
        />
      );
    } else if (item.cell_type === "raw") {
      return (
        <Raw
          cell={item}
          key={"raw" + index + this.state.sectionName}
          sourceFloder={this.state.sourceFloder}
        />
      );
    }
  }

  render() {
    const listItems = this.state.cells.map((item: any, index: number) =>
      this.generateCompoment(item, index)
    );
    if (this.state.sections.length > 0) {
      return (
        <div className="myApp">
          <div className="fixed-header-container">
            <Header
              sections={this.state.sections}
              changeSection={this.changeSection}
              current={this.state.currentSection}
            />
          </div>
          <div className="section-container">
            <div>{listItems}</div>
            <div className="pagination">
              {this.state.currentSection > 0 ? (
                <Button
                  onClick={() => {
                    this.changeSection(this.state.currentSection - 1);
                  }}
                >
                  上一页
                </Button>
              ) : (
                ""
              )}
              {this.state.currentSection < this.state.sections.length - 1 ? (
                <Button
                  onClick={() => {
                    this.changeSection(this.state.currentSection + 1);
                  }}
                  style={{ float: "right" }}
                >
                  下一页
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
          <Button
            className="redirect-btn"
            style={{
              background: "#ef8734",
              color: "white",
              borderRadius: "1.2em"
            }}
            onClick={this.redirect}
            ref={node => (this.redirectBtn = node)}
          >
            跳转到Plab
          </Button>
        </div>
      );
    } else if (this.state.loading) {
      return (
        <div className="myApp">
          <Icon
            type="loading"
            style={{ width: "100%", fontSize: "10em", marginTop: "1em" }}
          ></Icon>
          <p
            style={{
              margin: "auto",
              textAlign: "center",
              fontSize: "2em",
              padding: "1em 0 0 0"
            }}
          >
            Loading
          </p>
        </div>
      );
    } else if (!this.state.loading && this.state.sections.length === 0) {
      return (
        <div className="myApp">
          <Icon
            type="coffee"
            style={{ width: "100%", fontSize: "10em", marginTop: "1em" }}
          ></Icon>
          <p
            style={{
              margin: "auto",
              textAlign: "center",
              fontSize: "2em",
              padding: "1em 0 0 0"
            }}
          >
            没有找到对应章节
          </p>
        </div>
      );
    } else {
      return <div className="myApp"></div>;
    }
  }
}

export default PlabWeb;
