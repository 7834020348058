import React from "react";
import "./Style/Raw.css";
import { Radio, Tag, Button, Modal, Collapse, Divider, Input } from "antd";
import { getImageBaseUrl } from "./Util/urlHelper";
const { Panel } = Collapse;
const { TextArea } = Input;

class Raw extends React.Component<any, any> {
  source: any;
  content: string;
  type: string;
  contentObj: any;

  state = {
    radioValue: -1
  };

  constructor(props: any) {
    super(props);
    this.content = "";
    this.type = "";
    this.source = this.props.cell.source;
    this.replaceImgUrl = this.replaceImgUrl.bind(this);
    this.replaceContent();
  }

  replaceContent() {
    if (this.source.length > 1) {
      this.type = this.source[0];
      this.content = this.source[1];
      this.replaceImgUrl();
      this.contentObj = JSON.parse(this.content);
    } else if (this.source.length === 1) {
      if (this.source[0].startsWith("#_#_#mfeducation")) {
        this.type = "#_#_#mfeducation";
        this.content = this.source[0];
        this.content = this.content.replace("#_#_#mfeducation", "");
        this.replaceImgUrl();
        this.contentObj = JSON.parse(this.content);
      }
      //MFE_FILL_BLANK_KEY
      if (this.source[0].startsWith("MFE_FILL_BLANK_KEY")) {
        this.type = "MFE_FILL_BLANK_KEY";
        this.content = this.source[0];
        this.content = this.content.replace("MFE_FILL_BLANK_KEY", "");
        if (this.content) {
          this.replaceImgUrl();
          this.contentObj = JSON.parse(this.content);
        }
      }
    }
  }

  replaceImgUrl() {
    this.content = this.content.replace(
      new RegExp(/http:\/\/localhost:\d{4}\/files\/courses\/(.*?)\/media/, "g"),
      getImageBaseUrl() + this.props.sourceFloder + "media"
    );
  }

  showInfo = () => {
    Modal.success({
      title: "查看解析",
      content: (
        <div>
          <h2>{this.contentObj.choiceTitle}</h2>
          <p>正确答案:{this.contentObj.choiceAnswer}</p>
          <p>
            你的答案:
            {this.state.radioValue === 0
              ? "A"
              : this.state.radioValue === 1
              ? "B"
              : this.state.radioValue === 2
              ? "C"
              : this.state.radioValue === 3
              ? "D"
              : ""}
          </p>
          <p>题目解析:{this.contentObj.choiceOptions.choiceExplain}</p>
        </div>
      ),
      onOk() {}
    });
  };

  onChange = (e: any) => {
    this.setState({
      radioValue: e.target.value
    });
  };

  generateElements() {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px"
    };

    const customPanelStyle = {
      background: "#f7f7f7",
      borderRadius: 4,
      marginBottom: 24,
      border: 0,
      overflow: "hidden"
    };

    if (this.type === "#_#_#mfeducation") {
      return (
        <div className="raw-container">
          <div className="raw">
            <div>
              <Tag color="blue">单选</Tag>
              {this.contentObj.choiceTitle}
              <Button
                size="small"
                style={{ float: "right" }}
                onClick={this.showInfo}
              >
                查看解析
              </Button>
            </div>
            <Radio.Group onChange={this.onChange} value={this.state.radioValue}>
              {this.contentObj.choiceOptions.map((item: any, index: any) => (
                <Radio style={radioStyle} value={index} key={item + index}>
                  {item}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
      );
    } else if (this.type === "#_#mfe_help#_#\n") {
      let helpUI = <div />;
      let subUI = helpUI;
      let helpModels: {
        level: number;
        title: string;
        content: string;
        minus: number;
      }[] = this.contentObj.map((item: {}) => {
        return item;
      });
      helpModels.reverse().forEach(model => {
        subUI = helpUI;
        helpUI = (
          <Collapse bordered={false}>
            <Panel
              header={model.title}
              key={model.level.toString()}
              style={customPanelStyle}
            >
              <div dangerouslySetInnerHTML={{ __html: model.content }}></div>
              {subUI}
            </Panel>
          </Collapse>
        );
      });
      return (
        <div className="raw-container">
          <div className="raw">{helpUI}</div>
        </div>
      );
    } else if (this.type === "MFE_FILL_BLANK_KEY") {
      if (this.contentObj) {
        return (
          <div className="raw-container">
            <div className="raw">
              <Tag color="orange">简答题</Tag>
              <pre
                className="fill-blank-content"
                style={{ padding: "5px 0 0 0" }}
              >
                {this.contentObj ? this.contentObj.blankTitle : ""}
              </pre>
              <Divider orientation="left" className="fill-user-no-select">
                学生回答区域：
              </Divider>
              <TextArea
                className="answer-area"
                placeholder="请填写简答题答案:"
                autoSize={{ minRows: 2, maxRows: 6 }}
                defaultValue={this.contentObj ? this.contentObj.blankReply : ""}
              />
            </div>
          </div>
        );
      } else {
        return "";
      }
    } else {
      return <hr></hr>;
    }
  }

  render() {
    const isHr = this.content === "<hr/>";
    return isHr ? <hr /> : this.generateElements();
  }
}

export default Raw;
