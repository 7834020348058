const getBaseUrl = () => {
  const isDev = process.env.NODE_ENV === "development";
  return isDev ? "https://api.test.wonderbits.cn" : "https://api.wonderbits.cn";
};

const getImageBaseUrl = () => {
  const isDev = process.env.NODE_ENV === "development";
  return isDev
    ? "https://chapters-test.s3.cn-north-1.amazonaws.com.cn/"
    : "https://chapters.s3.cn-north-1.amazonaws.com.cn/";
};

const getRedirectUrl = () => {
  const isDev = process.env.NODE_ENV === "development";
  return isDev
    ? "wonderbits-plab-dev:?chapterName="
    : "wonderbits-plab:?chapterName=";
};

const getPlabDownloadUrl = (osName: string) => {
  const isWindows = osName === "Windows";
  return !isWindows
    ? "https://s3.cn-north-1.amazonaws.com.cn/download.wonderbits.cn/plab/Plab.exe"
    : "https://s3.cn-north-1.amazonaws.com.cn/download.wonderbits.cn/plab/Plab.dmg";
};

export { getBaseUrl, getImageBaseUrl, getRedirectUrl, getPlabDownloadUrl };
