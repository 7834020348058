import React from "react";
import hljs from "highlight.js";
import "./Style/Markdown.css";
import { getImageBaseUrl } from "./Util/urlHelper";

class Markdown extends React.Component<any, any> {
  source: any;
  content: string;
  node: any;

  constructor(props: any) {
    super(props);
    this.content = "";
    this.content = this.props.cell.source
      .join("")
      .replace(new RegExp("<p>===============</p>", "g"), "<hr/>");

    this.content = this.content.replace(
      new RegExp(/http:\/\/localhost:\d{4}\/files\/courses\/(.*?)\/media/, "g"),
      getImageBaseUrl() + this.props.sourceFloder + "media"
    );
  }

  componentDidMount() {
    if (this.content.indexOf("pre") > -1) {
      hljs.highlightBlock(this.node);
    }
  }

  render() {
    const isHr = this.content === "<hr/>";
    return isHr ? (
      <hr />
    ) : (
      <div className="markdown-cotainer">
        <div className="markdown">
          <div
            ref={node => (this.node = node)}
            dangerouslySetInnerHTML={{ __html: this.content }}
          ></div>
        </div>
      </div>
    );
  }
}

export default Markdown;
