import React from "react";
import "./Style/Code.css";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
hljs.registerLanguage("python", require("highlight.js/lib/languages/python"));

class Code extends React.Component<any, any> {
  node: any;
  componentDidMount() {
    if (this.props.cell.source.join("")) {
      hljs.highlightBlock(this.node);
    }
  }
  render() {
    let html = this.props.cell.source.join("");
    if (!html) {
      return "";
    } else {
      return (
        <div className="code-cotainer">
          <div className="code">
            <pre className="hljs" ref={node => (this.node = node)}>
              <code className="python">
                <div
                  dangerouslySetInnerHTML={{
                    __html: html
                  }}
                ></div>
              </code>
            </pre>
          </div>
        </div>
      );
    }
  }
}

export default Code;
