import React from "react";
import { Steps } from "antd";

import "./Style/Plab.css";

const { Step } = Steps;
class Header extends React.Component<any, any> {
  state = {
    current: 0
  };
  onChange = (current: number) => {
    this.props.changeSection(current);
  };

  render() {
    const steps = this.props.sections.map((item: any, index: number) => {
      return <Step title={item.name} key={item.name + index} />;
    });
    return (
      <div className="fixed-header">
        <Steps
          size="small"
          current={this.props.current}
          onChange={this.onChange}
        >
          {steps}
        </Steps>
      </div>
    );
  }
}
export default Header;
